import React, { useEffect, useRef, useState } from 'react';

const YouTubePlayer = () => {
    const playerRef = useRef(null);
    const [player, setPlayer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [error, setError] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(() => {
        const savedIndex = localStorage.getItem('lastPlayedVideoIndex');
        
        return savedIndex ? parseInt(savedIndex, 10) : 0;
    });
    const savedTime = localStorage.getItem('lastSavedTime');
    const [playlist] = useState([
        'y7fudcFIlZs',
        'MZFY9ockN4o',
        'ao4RCon11eY',
        'OZLUa8JUR18',
        'K6gL0QlQiHM',
        'f9eI0Wz72VI',
        '1WgRurG4IDM',
        'oABEGc8Dus0',
        'b3LJlZBWI8w',
        'S2TaAcwC_zI',
        '7leEmq6EMMs',
        '9uIAB_GNmGw',
        'm8QQR-wQA0I',
        'zKmWd8DPrEc',
        'WvV5TbJc9tQ',
        'b5amBoMIbOY',
        'gk7uyeyG9eU',
        'pVjdMQ_iAh0',
        'E-uwulZWpak',
        'AemkMbDeREM',
        'FqIACCH20JU',
        'Epj84QVw2rc',
        'oSHzUD-uqKY',
        'lAwYodrBr2Q',
        'W9Ul8EJ05Mo',
        'SSCzDykng4g'
    ]);
    const [videoDetails, setVideoDetails] = useState({ title: '', artist: '', thumbnailUrl: '' });
    const intervalRef = useRef(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLooping, setIsLooping] = useState(false);
    const [isShuffling, setIsShuffling] = useState(false);

    useEffect(() => {
        const loadYouTubeAPI = () => {
            if (!window.YT || !window.YT.Player) {
                const script = document.createElement('script');
                script.src = 'https://www.youtube.com/iframe_api';
                script.onload = () => setTimeout(initializePlayer, 1000);
                script.onerror = () => setError('Failed to load YouTube API script.');
                document.body.appendChild(script);
            } else {
                initializePlayer();
            }
        };

        const updatePlayTime = () => {
            if (player) {
                setCurrentTime(player.getCurrentTime());
                localStorage.setItem('lastSavedTime', player.getCurrentTime());
                const videoData = player.getVideoData();
                if (videoData) {
                    const { title, author: artist } = videoData;
                    setVideoDetails(prevDetails => {
                        if (prevDetails.title !== title || prevDetails.artist !== artist) {
                            return { title, artist, thumbnailUrl: "" };
                        }
                        return prevDetails;
                    });
                }
            }
        };

        intervalRef.current = setInterval(updatePlayTime, 500);

        const initializePlayer = () => {
            if (window.YT && window.YT.Player) {
                try {
                    const ytPlayer = new window.YT.Player(playerRef.current, {
                        height: '300',
                        width: '250',
                        videoId: playlist[currentVideoIndex],
                        playerVars: {
                            autoplay: isFirstLoad ? 0 : 1,  // Enable autoplay unless it's the first load
                            controls: 0
                        },
                        events: {
                            'onReady': (event) => {
                                const ytPlayer = event.target;
                                setPlayer(ytPlayer);
                                setDuration(ytPlayer.getDuration());
                                
                                // Load the last saved video or the first video in the playlist
                                const savedVideoId = localStorage.getItem('lastPlayedVideoId');
                                const videoToLoad = savedVideoId || playlist[currentVideoIndex];
                                handleVideoChange(videoToLoad);
                                
                                // After initialization, set the flag in localStorage
                                if (isFirstLoad) {
                                    localStorage.setItem('hasLoadedBefore', 'true');
                                    setIsFirstLoad(false);
                                    if(savedTime){
                                        ytPlayer.setVolume(0); 
                                        ytPlayer.seekTo(savedTime, true);
                                        ytPlayer.pauseVideo();
                                        //setIsPlaying(!isPlaying);
                                        
                                    }
                                }
                            },
                            'onError': (event) => setError(`YouTube Player Error: ${event.data}`),
                            'onStateChange': (event) => {
                                if (event.data === window.YT.PlayerState.ENDED) {
                                    handleNextVideo();
                                } else if (event.data === window.YT.PlayerState.PLAYING) {
                                    const ytPlayer = event.target;
                                    const videoData = ytPlayer.getVideoData();
                                    if (videoData && videoData.video_id) {
                                        handleVideoChange(videoData.video_id);
                                    } else {
                                        console.error('Video data is not available.');
                                    }
                                }
                            }
                        },
                    });
                } catch (err) {
                    setError('Error initializing YouTube player.');
                }
            } else {
                setError('YouTube API is not loaded yet.');
                setTimeout(initializePlayer, 1000);
            }
        };

        loadYouTubeAPI();

        return () => {
            if (player && player.destroy) {
                player.destroy();
            }
            const script = document.querySelector('script[src="https://www.youtube.com/iframe_api"]');
            if (script) {
                document.body.removeChild(script);
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [currentVideoIndex, player, isFirstLoad]);

    const handleVideoChange = async (videoId, reload = false) => {
        if (player) {
            if (reload) {
                player.loadVideoById(videoId);
            }
            localStorage.setItem('lastPlayedVideoId', videoId);
        } else {
            console.error('Player is not initialized');
        }       
    };
    
    const handleNextVideo = () => {
        setCurrentVideoIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % playlist.length;
            localStorage.setItem('lastPlayedVideoIndex', nextIndex);
            setIsPlaying(true);
            handleVideoChange(playlist[nextIndex]);
            return nextIndex;
        });
        setIsFirstLoad(false);
    };
    
    const handlePrevVideo = () => {
        if (player) {
            if (player.getCurrentTime() < 5) {
                setCurrentVideoIndex((currentIndex) => {
                    const prevIndex = (currentIndex - 1 + playlist.length) % playlist.length;
                    localStorage.setItem('lastPlayedVideoIndex', prevIndex);
                    setIsPlaying(true);
                    handleVideoChange(playlist[prevIndex]);
                    return prevIndex;
                });
            } else {
                player.seekTo(0, true);
            }
        }
        setIsFirstLoad(false);
    };

    const fadeVolume = (targetVolume, duration, callback) => {
        if (!player) return;
    
        const initialVolume = player.getVolume(); // Volume in 0-100 range
        const steps = 20; // Number of steps for the fade effect
        const stepDuration = duration / steps; // Duration of each step
    
        let step = 0;
    
        const interval = setInterval(() => {
            step++;
            const progress = step / steps;
            const newVolume = initialVolume + progress * (targetVolume - initialVolume);
            player.setVolume(Math.max(0, Math.min(newVolume, 100))); // Volume in 0-100 range
    
            console.log(`Step: ${step}, Volume: ${newVolume}`); // Debugging line
    
            if (step >= steps) {
                clearInterval(interval);
                player.setVolume(targetVolume); // Ensure final volume is exactly the target
                if (callback) callback(); // Execute the callback function
            }
        }, stepDuration);
    };
    
    const togglePlayPause = () => {
        if (player) {
            if (isPlaying) {
                fadeVolume(0, 1000, () => {
                    console.log('Volume faded out, now pausing video.');
                    player.pauseVideo(); // Pause video after fade out is complete
                });
            } else {
                fadeVolume(100, 2000, () => {
                    console.log('Volume faded in, now playing video.');
                    
                });
                player.playVideo(); // Play video after fade in is complete
            }
            setIsPlaying(!isPlaying);
        }
        setIsFirstLoad(false);
    };
    const handleProgressClick = (e) => {
        const progressBar = e.currentTarget;
        const rect = progressBar.getBoundingClientRect();
        const clickPosition = e.clientX - rect.left;
        const progressPercentage = clickPosition / rect.width;
        const newTime = progressPercentage * duration;
        
        if (player) {
            player.seekTo(newTime, true);
        }
    };

    const fetchVideoDetails = async () => {
        if (player && typeof player.getVideoData === 'function') {
            const videoData = player.getVideoData();
            if (videoData) {
                const { title, author: artist } = videoData;
                setVideoDetails(prevDetails => {
                    if (prevDetails.title !== title || prevDetails.artist !== artist) {
                        return { title, artist, thumbnailUrl: "" };
                    }
                    return prevDetails;
                });
            }
        }
    };

    const toggleLooping = () => {
        if (player) {
            player.setLoop(isLooping ? 0 : 1);
            setIsLooping(!isLooping);
        }
    };

    const toggleShuffling = () => {
        if (player) {
            player.setShuffle(isShuffling ? 0 : 1);
            setIsShuffling(!isShuffling);
        }
    };
    
    return (
        <div>
            <div className="album-art emboss">
            <div className="album-overlay fade fade-in fade-out" style={{ opacity: isPlaying ? "0" : "1" }}><img src="images/favicon.png"/></div>
                <div ref={playerRef} id="player"></div>
                
            </div> 
            <div className="song-info">
                <div id="video-title" className="song-title">{videoDetails.title || ''}</div>
                <div id="video-artist" className="song-artist">{videoDetails.artist || ''}</div>
            </div>
            <div className="time-info">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
            </div>
            <div
                className="progress-bar"
                onClick={handleProgressClick}
            >
                <div
                    className="progress"
                    style={{ width: `${ (currentTime / duration) * 100 }%` }}
                ></div>
            </div>
            {/* <div className="loopshuffle">
                <div
                    className='loopshuffle-button'
                    onClick={toggleLooping}
                    style={{ opacity: isLooping ? 1 : 0.5 }}>
                    <img src="images/icons/loop.png" alt="Loop" />
                </div>
                <div
                    className='loopshuffle-button'
                    onClick={toggleShuffling}
                    style={{ opacity: isShuffling ? 1 : 0.5 }}>
                    <img src="images/icons/shuffle.png" alt="Shuffle" />
                </div>
            </div> */}
            <div className="controls">
                <div className="control-button prev-button embosssmall" onClick={handlePrevVideo}>
                    <img src="images/icons/prev.png" alt="Previous" />
                </div>
                <div className="control-button play-button embosssmall" id="playbtn" onClick={togglePlayPause}>
                    <img src={isPlaying ? "images/icons/pause.png" : "images/icons/play.png"} alt={isPlaying ? "Pause" : "Play"} />
                </div>
                <div className="control-button next-button embosssmall" onClick={handleNextVideo}>
                    <img src="images/icons/next.png" alt="Next" />
                </div>
            </div>
            {error && <div className="error">{error}</div>}
        </div>
    );
};

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

export default YouTubePlayer;
